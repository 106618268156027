.App {
  text-align: center;
  /* max-width: 33%; */
  width: 320px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1rem;
  position: relative;
}

/* .main {
  top: 0;
  position: center;
  background-image: url('../public/tamagotchi_bg.png');
  background-repeat: no-repeat;
  // background-attachment: fixed; 
  background-position: center;
  height: 1188px;
  // min-height: 100%;
  background-size: contain;
} */

/* body {
  background-color: #D1C174;
  background-size: 100%;
  background-attachment: fixed;
} */

button {
  cursor: pointer;
}

/* div {
  border: 1px solid red;
} */

.bottomPanel {
  display: flex;
  justify-content: center;
  align-items: center;
}