.actions {
    /* border: 1px solid red; */
    width: 350px;
    height: max-content;
    padding-top: 10px;
}

.actionButton {
    background-color: white;
    color: black;
    border: none;
    vertical-align: top;
    box-shadow: 0 2px 4px darkslategray;
    margin:10px;
    font-size: .8rem;
    height: 80px;
    width: 80px;
    font-family: "Belanosima", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.roundButton {
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    border-radius: 50%;
}

@media(hover: hover) and (pointer: fine) {
    .actionButton:hover {
        color: white;
        background:#6658a4;
    }
}

.actionButton:active {
    background-color: #c7c2da;
    background-size: 100%;
    transition: background 0s;
    transform: scale(.90);
  }