.topPanelContainer {
    position: relative;
    /* border: 2px solid black; */
    border-radius: 3%;
    /* height: 100px; */
    width: 100%;
    aspect-ratio : 1 / 1;
    background-image: url('../../public/screen_bg.png');
    background-repeat: no-repeat;
}

.topPanel {
    display: grid;
    /* grid-template-columns: 50px 1fr; */
    grid-template-columns: 60px 260px;
    grid-template-rows: 240px 80px;
    height: 100%;
}

.statusIconGroup {
    /* border: 1px solid blue; */
    grid-area: 1 / 1 / 2 / 2;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
}

.spriteContainer {
    /* border: 2px solid green; */
    position: relative;
    grid-area: 1 / 2 / 2 / 3;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    display: grid;
    grid-template-columns: 200px 60px;
    grid-template-rows: 50px 1fr;
    width: 100%;
}

.sprite {
    grid-area: 1 / 1 / 3 / 2;
    /* border: 1px solid blue; */
    /* width: fit-content;
    height: fit-content; */
    /* width: 230px;
    height: 230px; */
}

.battery {
    grid-area: 1 / 2 / 2 / 3;
    position: absolute;
    width: 40px;
    bottom: 0;
    right: 20%;
    z-index: 1;
}

.progressContainer {
    grid-area: 2 / 2 / 3 / 3;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.progressBar {
    position: relative;
    border: 3px solid black;
    height: 100px;
    width: 10px;
    margin-right: 30%;
    margin-bottom: 10px;
    /* margin: auto; */
    /* margin-top: 40px; */
}

.progressFiller {
    background: #ffc107;
    opacity: 60%;
    width: 100%;
    position: absolute;
    bottom: 0;
    transition: height .1s ease-in;
}

.messageContainer {
    grid-area: 2 / 1 / 3 / 3;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    max-height: 80px;
  }

  .statusLog {
    display: relative;
    max-width: 100%;
    text-wrap: wrap;
    list-style-type: none;
    margin-bottom: 10px;
    padding: 0px;
    font-size: 1rem;
    font-family: "Belanosima", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  li {
    display: absolute;
    max-width: 300px;
    margin: auto;
  }

  .board {
    grid-area: 2 / 1 / 3 / 2;
    margin: auto;
    display: grid;
    grid-template-rows: 50px 50px 50px;
    grid-template-columns: 50px 50px 50px;
  }
  
  .cell, .cell:disabled {
    border: 2px solid black;
    height: 100%;
    width: 100%;
    color: black;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cellIcon {
    height: 80%;
  }

  .simonContainer {
    grid-area: 2 / 1 / 3 / 2;
    margin: auto;
    display: grid;
    height: 80%;
    width: 80%;
    grid-template-rows: (3, 1fr);
    grid-template-columns: (3, 1fr);
  }

  .simonButton {
    box-shadow: 0 2px 2px darkslategray;
    border: none;
  }

.simonButton:active {
    background-color: #c7c2da;
    background-size: 100%;
    transition: background 0s;
    transform: scale(.90);
  }

  .simonButton:nth-child(1) {
    background-color: darkblue;
    grid-column: 2;
    grid-row: 1;
  }

  @media(hover: hover) and (pointer: fine) {
    .simonButton:nth-child(1):hover {
        background: radial-gradient(rgb(187, 187, 214), darkblue);
    }
  }

  .simonButton:nth-child(2) {
    background-color: darkgreen;
    grid-column: 1;
    grid-row: 2;
  }

  @media(hover: hover) and (pointer: fine) {
    .simonButton:nth-child(2):hover {
        background: radial-gradient(rgb(187, 220, 187), darkgreen);
    }
  }

  .simonButton:nth-child(3) {
    background-color: crimson;
    grid-column: 3;
    grid-row: 2;
  }

  @media(hover: hover) and (pointer: fine) {
    .simonButton:nth-child(3):hover {
        background: radial-gradient(rgb(231, 204, 209), crimson);
    }
  }

  .simonButton:nth-child(4) {
    background-color: #ffc107;
    grid-column: 2;
    grid-row: 3;
  }

  @media(hover: hover) and (pointer: fine) {
    .simonButton:nth-child(4):hover {
        background: radial-gradient(#ece4ce, #ffc107);
    }
  }
  
  /* remove border for edges */
  .cell:nth-child(1), .cell:nth-child(2), .cell:nth-child(3) {
      border-top: none;
  }
  
  .cell:nth-child(1), .cell:nth-child(4), .cell:nth-child(7) {
      border-left: none;
  }
  
  .cell:nth-child(3), .cell:nth-child(6), .cell:nth-child(9) {
      border-right: none;
  }
  
  .cell:nth-child(7), .cell:nth-child(8), .cell:nth-child(9) {
      border-bottom: none;
  }